/**
 * METS API Documentation
 * METS API Documentation
 *
 * The version of the OpenAPI document: uk-pmrv-app-api 0.81.0-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpClient, HttpEvent, HttpHeaders, HttpParameterCodec, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';

import { Configuration } from '../configuration';
import { CustomHttpParameterCodec } from '../encoder';
import { AerodromePairsTotalEmissions } from '../model/aerodromePairsTotalEmissions';
import { AviationAerCorsiaAerodromePairsTotalEmissions } from '../model/aviationAerCorsiaAerodromePairsTotalEmissions';
import { AviationAerCorsiaEmissionsCalculationDTO } from '../model/aviationAerCorsiaEmissionsCalculationDTO';
import { AviationAerCorsiaInternationalFlightsEmissions } from '../model/aviationAerCorsiaInternationalFlightsEmissions';
import { AviationAerCorsiaInternationalFlightsEmissionsCalculationDTO } from '../model/aviationAerCorsiaInternationalFlightsEmissionsCalculationDTO';
import { AviationAerCorsiaStandardFuelsTotalEmissions } from '../model/aviationAerCorsiaStandardFuelsTotalEmissions';
import { AviationAerCorsiaTotalEmissions } from '../model/aviationAerCorsiaTotalEmissions';
import { AviationAerDomesticFlightsEmissions } from '../model/aviationAerDomesticFlightsEmissions';
import { AviationAerEmissionsCalculationDTO } from '../model/aviationAerEmissionsCalculationDTO';
import { AviationAerNonDomesticFlightsEmissions } from '../model/aviationAerNonDomesticFlightsEmissions';
import { AviationAerTotalEmissions } from '../model/aviationAerTotalEmissions';
import { StandardFuelsTotalEmissions } from '../model/standardFuelsTotalEmissions';
import { BASE_PATH } from '../variables';

@Injectable({
  providedIn: 'root',
})
export class AviationReportingService {
  protected basePath = 'http://localhost:8080/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach((elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k)),
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Calculate total emissions for aerodrome pairs
   * @param aviationAerCorsiaEmissionsCalculationDTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAerodromePairsEmissionsCorsia(
    aviationAerCorsiaEmissionsCalculationDTO: AviationAerCorsiaEmissionsCalculationDTO,
  ): Observable<Array<AviationAerCorsiaAerodromePairsTotalEmissions>>;
  public getAerodromePairsEmissionsCorsia(
    aviationAerCorsiaEmissionsCalculationDTO: AviationAerCorsiaEmissionsCalculationDTO,
    observe: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<Array<AviationAerCorsiaAerodromePairsTotalEmissions>>>;
  public getAerodromePairsEmissionsCorsia(
    aviationAerCorsiaEmissionsCalculationDTO: AviationAerCorsiaEmissionsCalculationDTO,
    observe: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<Array<AviationAerCorsiaAerodromePairsTotalEmissions>>>;
  public getAerodromePairsEmissionsCorsia(
    aviationAerCorsiaEmissionsCalculationDTO: AviationAerCorsiaEmissionsCalculationDTO,
    observe: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<Array<AviationAerCorsiaAerodromePairsTotalEmissions>>;
  public getAerodromePairsEmissionsCorsia(
    aviationAerCorsiaEmissionsCalculationDTO: AviationAerCorsiaEmissionsCalculationDTO,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (aviationAerCorsiaEmissionsCalculationDTO === null || aviationAerCorsiaEmissionsCalculationDTO === undefined) {
      throw new Error(
        'Required parameter aviationAerCorsiaEmissionsCalculationDTO was null or undefined when calling getAerodromePairsEmissionsCorsia.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    const credential = this.configuration.lookupCredential('bearerAuth');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType_ = 'text';
    }

    return this.httpClient.post<Array<AviationAerCorsiaAerodromePairsTotalEmissions>>(
      `${this.configuration.basePath}/v1.0/aviation-reporting/corsia/aerodrome-pairs-emissions`,
      aviationAerCorsiaEmissionsCalculationDTO,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Calculate total emissions for aerodrome pairs
   * @param aviationAerEmissionsCalculationDTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAerodromePairsEmissionsUkEts(
    aviationAerEmissionsCalculationDTO: AviationAerEmissionsCalculationDTO,
  ): Observable<Array<AerodromePairsTotalEmissions>>;
  public getAerodromePairsEmissionsUkEts(
    aviationAerEmissionsCalculationDTO: AviationAerEmissionsCalculationDTO,
    observe: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<Array<AerodromePairsTotalEmissions>>>;
  public getAerodromePairsEmissionsUkEts(
    aviationAerEmissionsCalculationDTO: AviationAerEmissionsCalculationDTO,
    observe: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<Array<AerodromePairsTotalEmissions>>>;
  public getAerodromePairsEmissionsUkEts(
    aviationAerEmissionsCalculationDTO: AviationAerEmissionsCalculationDTO,
    observe: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<Array<AerodromePairsTotalEmissions>>;
  public getAerodromePairsEmissionsUkEts(
    aviationAerEmissionsCalculationDTO: AviationAerEmissionsCalculationDTO,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (aviationAerEmissionsCalculationDTO === null || aviationAerEmissionsCalculationDTO === undefined) {
      throw new Error(
        'Required parameter aviationAerEmissionsCalculationDTO was null or undefined when calling getAerodromePairsEmissionsUkEts.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    const credential = this.configuration.lookupCredential('bearerAuth');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType_ = 'text';
    }

    return this.httpClient.post<Array<AerodromePairsTotalEmissions>>(
      `${this.configuration.basePath}/v1.0/aviation-reporting/ukets/aerodrome-pairs-emissions`,
      aviationAerEmissionsCalculationDTO,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Retrieves domestic flights emissions
   * @param aviationAerEmissionsCalculationDTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDomesticFlightsEmissionsUkEts(
    aviationAerEmissionsCalculationDTO: AviationAerEmissionsCalculationDTO,
  ): Observable<AviationAerDomesticFlightsEmissions>;
  public getDomesticFlightsEmissionsUkEts(
    aviationAerEmissionsCalculationDTO: AviationAerEmissionsCalculationDTO,
    observe: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<AviationAerDomesticFlightsEmissions>>;
  public getDomesticFlightsEmissionsUkEts(
    aviationAerEmissionsCalculationDTO: AviationAerEmissionsCalculationDTO,
    observe: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<AviationAerDomesticFlightsEmissions>>;
  public getDomesticFlightsEmissionsUkEts(
    aviationAerEmissionsCalculationDTO: AviationAerEmissionsCalculationDTO,
    observe: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<AviationAerDomesticFlightsEmissions>;
  public getDomesticFlightsEmissionsUkEts(
    aviationAerEmissionsCalculationDTO: AviationAerEmissionsCalculationDTO,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (aviationAerEmissionsCalculationDTO === null || aviationAerEmissionsCalculationDTO === undefined) {
      throw new Error(
        'Required parameter aviationAerEmissionsCalculationDTO was null or undefined when calling getDomesticFlightsEmissionsUkEts.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    const credential = this.configuration.lookupCredential('bearerAuth');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType_ = 'text';
    }

    return this.httpClient.post<AviationAerDomesticFlightsEmissions>(
      `${this.configuration.basePath}/v1.0/aviation-reporting/ukets/domestic-flights-emissions`,
      aviationAerEmissionsCalculationDTO,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Retrieves corsia international flights emissions
   * @param aviationAerCorsiaInternationalFlightsEmissionsCalculationDTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getInternationalFlightsEmissionsCorsia(
    aviationAerCorsiaInternationalFlightsEmissionsCalculationDTO: AviationAerCorsiaInternationalFlightsEmissionsCalculationDTO,
  ): Observable<AviationAerCorsiaInternationalFlightsEmissions>;
  public getInternationalFlightsEmissionsCorsia(
    aviationAerCorsiaInternationalFlightsEmissionsCalculationDTO: AviationAerCorsiaInternationalFlightsEmissionsCalculationDTO,
    observe: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<AviationAerCorsiaInternationalFlightsEmissions>>;
  public getInternationalFlightsEmissionsCorsia(
    aviationAerCorsiaInternationalFlightsEmissionsCalculationDTO: AviationAerCorsiaInternationalFlightsEmissionsCalculationDTO,
    observe: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<AviationAerCorsiaInternationalFlightsEmissions>>;
  public getInternationalFlightsEmissionsCorsia(
    aviationAerCorsiaInternationalFlightsEmissionsCalculationDTO: AviationAerCorsiaInternationalFlightsEmissionsCalculationDTO,
    observe: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<AviationAerCorsiaInternationalFlightsEmissions>;
  public getInternationalFlightsEmissionsCorsia(
    aviationAerCorsiaInternationalFlightsEmissionsCalculationDTO: AviationAerCorsiaInternationalFlightsEmissionsCalculationDTO,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (
      aviationAerCorsiaInternationalFlightsEmissionsCalculationDTO === null ||
      aviationAerCorsiaInternationalFlightsEmissionsCalculationDTO === undefined
    ) {
      throw new Error(
        'Required parameter aviationAerCorsiaInternationalFlightsEmissionsCalculationDTO was null or undefined when calling getInternationalFlightsEmissionsCorsia.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    const credential = this.configuration.lookupCredential('bearerAuth');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType_ = 'text';
    }

    return this.httpClient.post<AviationAerCorsiaInternationalFlightsEmissions>(
      `${this.configuration.basePath}/v1.0/aviation-reporting/corsia/international-flights-emissions`,
      aviationAerCorsiaInternationalFlightsEmissionsCalculationDTO,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Retrieves domestic flights emissions
   * @param aviationAerEmissionsCalculationDTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getNonDomesticFlightsEmissionsUkEts(
    aviationAerEmissionsCalculationDTO: AviationAerEmissionsCalculationDTO,
  ): Observable<AviationAerNonDomesticFlightsEmissions>;
  public getNonDomesticFlightsEmissionsUkEts(
    aviationAerEmissionsCalculationDTO: AviationAerEmissionsCalculationDTO,
    observe: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<AviationAerNonDomesticFlightsEmissions>>;
  public getNonDomesticFlightsEmissionsUkEts(
    aviationAerEmissionsCalculationDTO: AviationAerEmissionsCalculationDTO,
    observe: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<AviationAerNonDomesticFlightsEmissions>>;
  public getNonDomesticFlightsEmissionsUkEts(
    aviationAerEmissionsCalculationDTO: AviationAerEmissionsCalculationDTO,
    observe: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<AviationAerNonDomesticFlightsEmissions>;
  public getNonDomesticFlightsEmissionsUkEts(
    aviationAerEmissionsCalculationDTO: AviationAerEmissionsCalculationDTO,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (aviationAerEmissionsCalculationDTO === null || aviationAerEmissionsCalculationDTO === undefined) {
      throw new Error(
        'Required parameter aviationAerEmissionsCalculationDTO was null or undefined when calling getNonDomesticFlightsEmissionsUkEts.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    const credential = this.configuration.lookupCredential('bearerAuth');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType_ = 'text';
    }

    return this.httpClient.post<AviationAerNonDomesticFlightsEmissions>(
      `${this.configuration.basePath}/v1.0/aviation-reporting/ukets/non-domestic-flights-emissions`,
      aviationAerEmissionsCalculationDTO,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Retrieves total emissions for standard fuels
   * @param aviationAerCorsiaEmissionsCalculationDTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getStandardFuelsEmissionsCorsia(
    aviationAerCorsiaEmissionsCalculationDTO: AviationAerCorsiaEmissionsCalculationDTO,
  ): Observable<Array<AviationAerCorsiaStandardFuelsTotalEmissions>>;
  public getStandardFuelsEmissionsCorsia(
    aviationAerCorsiaEmissionsCalculationDTO: AviationAerCorsiaEmissionsCalculationDTO,
    observe: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<Array<AviationAerCorsiaStandardFuelsTotalEmissions>>>;
  public getStandardFuelsEmissionsCorsia(
    aviationAerCorsiaEmissionsCalculationDTO: AviationAerCorsiaEmissionsCalculationDTO,
    observe: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<Array<AviationAerCorsiaStandardFuelsTotalEmissions>>>;
  public getStandardFuelsEmissionsCorsia(
    aviationAerCorsiaEmissionsCalculationDTO: AviationAerCorsiaEmissionsCalculationDTO,
    observe: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<Array<AviationAerCorsiaStandardFuelsTotalEmissions>>;
  public getStandardFuelsEmissionsCorsia(
    aviationAerCorsiaEmissionsCalculationDTO: AviationAerCorsiaEmissionsCalculationDTO,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (aviationAerCorsiaEmissionsCalculationDTO === null || aviationAerCorsiaEmissionsCalculationDTO === undefined) {
      throw new Error(
        'Required parameter aviationAerCorsiaEmissionsCalculationDTO was null or undefined when calling getStandardFuelsEmissionsCorsia.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    const credential = this.configuration.lookupCredential('bearerAuth');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType_ = 'text';
    }

    return this.httpClient.post<Array<AviationAerCorsiaStandardFuelsTotalEmissions>>(
      `${this.configuration.basePath}/v1.0/aviation-reporting/corsia/standard-fuels-emissions`,
      aviationAerCorsiaEmissionsCalculationDTO,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Retrieves total emissions for standard fuels
   * @param aviationAerEmissionsCalculationDTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getStandardFuelsEmissionsUkEts(
    aviationAerEmissionsCalculationDTO: AviationAerEmissionsCalculationDTO,
  ): Observable<Array<StandardFuelsTotalEmissions>>;
  public getStandardFuelsEmissionsUkEts(
    aviationAerEmissionsCalculationDTO: AviationAerEmissionsCalculationDTO,
    observe: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<Array<StandardFuelsTotalEmissions>>>;
  public getStandardFuelsEmissionsUkEts(
    aviationAerEmissionsCalculationDTO: AviationAerEmissionsCalculationDTO,
    observe: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<Array<StandardFuelsTotalEmissions>>>;
  public getStandardFuelsEmissionsUkEts(
    aviationAerEmissionsCalculationDTO: AviationAerEmissionsCalculationDTO,
    observe: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<Array<StandardFuelsTotalEmissions>>;
  public getStandardFuelsEmissionsUkEts(
    aviationAerEmissionsCalculationDTO: AviationAerEmissionsCalculationDTO,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (aviationAerEmissionsCalculationDTO === null || aviationAerEmissionsCalculationDTO === undefined) {
      throw new Error(
        'Required parameter aviationAerEmissionsCalculationDTO was null or undefined when calling getStandardFuelsEmissionsUkEts.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    const credential = this.configuration.lookupCredential('bearerAuth');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType_ = 'text';
    }

    return this.httpClient.post<Array<StandardFuelsTotalEmissions>>(
      `${this.configuration.basePath}/v1.0/aviation-reporting/ukets/standard-fuels-emissions`,
      aviationAerEmissionsCalculationDTO,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Calculate total emissions for the scheme year
   * @param aviationAerCorsiaEmissionsCalculationDTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getTotalEmissionsCorsia(
    aviationAerCorsiaEmissionsCalculationDTO: AviationAerCorsiaEmissionsCalculationDTO,
  ): Observable<AviationAerCorsiaTotalEmissions>;
  public getTotalEmissionsCorsia(
    aviationAerCorsiaEmissionsCalculationDTO: AviationAerCorsiaEmissionsCalculationDTO,
    observe: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<AviationAerCorsiaTotalEmissions>>;
  public getTotalEmissionsCorsia(
    aviationAerCorsiaEmissionsCalculationDTO: AviationAerCorsiaEmissionsCalculationDTO,
    observe: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<AviationAerCorsiaTotalEmissions>>;
  public getTotalEmissionsCorsia(
    aviationAerCorsiaEmissionsCalculationDTO: AviationAerCorsiaEmissionsCalculationDTO,
    observe: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<AviationAerCorsiaTotalEmissions>;
  public getTotalEmissionsCorsia(
    aviationAerCorsiaEmissionsCalculationDTO: AviationAerCorsiaEmissionsCalculationDTO,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (aviationAerCorsiaEmissionsCalculationDTO === null || aviationAerCorsiaEmissionsCalculationDTO === undefined) {
      throw new Error(
        'Required parameter aviationAerCorsiaEmissionsCalculationDTO was null or undefined when calling getTotalEmissionsCorsia.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    const credential = this.configuration.lookupCredential('bearerAuth');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType_ = 'text';
    }

    return this.httpClient.post<AviationAerCorsiaTotalEmissions>(
      `${this.configuration.basePath}/v1.0/aviation-reporting/corsia/total-emissions`,
      aviationAerCorsiaEmissionsCalculationDTO,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Calculate total emissions for the scheme year
   * @param aviationAerEmissionsCalculationDTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getTotalEmissionsUkEts(
    aviationAerEmissionsCalculationDTO: AviationAerEmissionsCalculationDTO,
  ): Observable<AviationAerTotalEmissions>;
  public getTotalEmissionsUkEts(
    aviationAerEmissionsCalculationDTO: AviationAerEmissionsCalculationDTO,
    observe: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<AviationAerTotalEmissions>>;
  public getTotalEmissionsUkEts(
    aviationAerEmissionsCalculationDTO: AviationAerEmissionsCalculationDTO,
    observe: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<AviationAerTotalEmissions>>;
  public getTotalEmissionsUkEts(
    aviationAerEmissionsCalculationDTO: AviationAerEmissionsCalculationDTO,
    observe: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<AviationAerTotalEmissions>;
  public getTotalEmissionsUkEts(
    aviationAerEmissionsCalculationDTO: AviationAerEmissionsCalculationDTO,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (aviationAerEmissionsCalculationDTO === null || aviationAerEmissionsCalculationDTO === undefined) {
      throw new Error(
        'Required parameter aviationAerEmissionsCalculationDTO was null or undefined when calling getTotalEmissionsUkEts.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    const credential = this.configuration.lookupCredential('bearerAuth');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType_ = 'text';
    }

    return this.httpClient.post<AviationAerTotalEmissions>(
      `${this.configuration.basePath}/v1.0/aviation-reporting/ukets/total-emissions`,
      aviationAerEmissionsCalculationDTO,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }
}
