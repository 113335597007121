/**
 * METS API Documentation
 * METS API Documentation
 *
 * The version of the OpenAPI document: uk-pmrv-app-api 0.81.0-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpClient, HttpEvent, HttpHeaders, HttpParameterCodec, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';

import { Configuration } from '../configuration';
import { CustomHttpParameterCodec } from '../encoder';
import { ChargingZoneDTO } from '../model/chargingZoneDTO';
import { InventoryDataYearExistenceDTO } from '../model/inventoryDataYearExistenceDTO';
import { NationalInventoryDataDTO } from '../model/nationalInventoryDataDTO';
import { RegionalInventoryEmissionCalculationParamsDTO } from '../model/regionalInventoryEmissionCalculationParamsDTO';
import { SourceStreamCalculationParametersInfo } from '../model/sourceStreamCalculationParametersInfo';
import { BASE_PATH } from '../variables';

@Injectable({
  providedIn: 'root',
})
export class ReportingDataService {
  protected basePath = 'http://localhost:8080/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach((elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k)),
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Returns charging zones associated with the provided post code
   * @param code The post code
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getChargingZonesByPostCode(code: string): Observable<Array<ChargingZoneDTO>>;
  public getChargingZonesByPostCode(
    code: string,
    observe: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<Array<ChargingZoneDTO>>>;
  public getChargingZonesByPostCode(
    code: string,
    observe: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<Array<ChargingZoneDTO>>>;
  public getChargingZonesByPostCode(
    code: string,
    observe: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<Array<ChargingZoneDTO>>;
  public getChargingZonesByPostCode(
    code: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (code === null || code === undefined) {
      throw new Error('Required parameter code was null or undefined when calling getChargingZonesByPostCode.');
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (code !== undefined && code !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>code, 'code');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    const credential = this.configuration.lookupCredential('bearerAuth');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType_ = 'text';
    }

    return this.httpClient.get<Array<ChargingZoneDTO>>(
      `${this.configuration.basePath}/v1.0/reporting-data/charging-zones`,
      {
        params: queryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Returns reporting year inventory data existence
   * @param year The reporting year
   * @param method The calculation method type
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getInventoryDataExistenceByYear(
    year: string,
    method: 'NATIONAL' | 'REGIONAL',
  ): Observable<InventoryDataYearExistenceDTO>;
  public getInventoryDataExistenceByYear(
    year: string,
    method: 'NATIONAL' | 'REGIONAL',
    observe: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<InventoryDataYearExistenceDTO>>;
  public getInventoryDataExistenceByYear(
    year: string,
    method: 'NATIONAL' | 'REGIONAL',
    observe: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<InventoryDataYearExistenceDTO>>;
  public getInventoryDataExistenceByYear(
    year: string,
    method: 'NATIONAL' | 'REGIONAL',
    observe: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<InventoryDataYearExistenceDTO>;
  public getInventoryDataExistenceByYear(
    year: string,
    method: 'NATIONAL' | 'REGIONAL',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (year === null || year === undefined) {
      throw new Error('Required parameter year was null or undefined when calling getInventoryDataExistenceByYear.');
    }
    if (method === null || method === undefined) {
      throw new Error('Required parameter method was null or undefined when calling getInventoryDataExistenceByYear.');
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (year !== undefined && year !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>year, 'year');
    }
    if (method !== undefined && method !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>method, 'method');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    const credential = this.configuration.lookupCredential('bearerAuth');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType_ = 'text';
    }

    return this.httpClient.get<InventoryDataYearExistenceDTO>(`${this.configuration.basePath}/v1.0/reporting-data`, {
      params: queryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Returns national inventory data for the provided year
   * @param year The reporting year
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getNationalInventoryData(year: string): Observable<NationalInventoryDataDTO>;
  public getNationalInventoryData(
    year: string,
    observe: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<NationalInventoryDataDTO>>;
  public getNationalInventoryData(
    year: string,
    observe: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<NationalInventoryDataDTO>>;
  public getNationalInventoryData(
    year: string,
    observe: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<NationalInventoryDataDTO>;
  public getNationalInventoryData(
    year: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (year === null || year === undefined) {
      throw new Error('Required parameter year was null or undefined when calling getNationalInventoryData.');
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (year !== undefined && year !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>year, 'year');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    const credential = this.configuration.lookupCredential('bearerAuth');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType_ = 'text';
    }

    return this.httpClient.get<NationalInventoryDataDTO>(
      `${this.configuration.basePath}/v1.0/reporting-data/national-inventory-data`,
      {
        params: queryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Returns regional emission calculation parameters for the provided year and charging zone
   * @param year The reporting year
   * @param chargingZoneCode The charging zone code
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRegionalInventoryEmissionCalculationParams(
    year: string,
    chargingZoneCode: string,
  ): Observable<RegionalInventoryEmissionCalculationParamsDTO>;
  public getRegionalInventoryEmissionCalculationParams(
    year: string,
    chargingZoneCode: string,
    observe: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<RegionalInventoryEmissionCalculationParamsDTO>>;
  public getRegionalInventoryEmissionCalculationParams(
    year: string,
    chargingZoneCode: string,
    observe: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<RegionalInventoryEmissionCalculationParamsDTO>>;
  public getRegionalInventoryEmissionCalculationParams(
    year: string,
    chargingZoneCode: string,
    observe: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<RegionalInventoryEmissionCalculationParamsDTO>;
  public getRegionalInventoryEmissionCalculationParams(
    year: string,
    chargingZoneCode: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (year === null || year === undefined) {
      throw new Error(
        'Required parameter year was null or undefined when calling getRegionalInventoryEmissionCalculationParams.',
      );
    }
    if (chargingZoneCode === null || chargingZoneCode === undefined) {
      throw new Error(
        'Required parameter chargingZoneCode was null or undefined when calling getRegionalInventoryEmissionCalculationParams.',
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (year !== undefined && year !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>year, 'year');
    }
    if (chargingZoneCode !== undefined && chargingZoneCode !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>chargingZoneCode, 'chargingZoneCode');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    const credential = this.configuration.lookupCredential('bearerAuth');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType_ = 'text';
    }

    return this.httpClient.get<RegionalInventoryEmissionCalculationParamsDTO>(
      `${this.configuration.basePath}/v1.0/reporting-data/regional-inventory-data`,
      {
        params: queryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Returns calculation parameters information by source stream type
   * @param sourceStreamType The source stream type
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSourceStreamEmissionsCalculationInfoByType(
    sourceStreamType:
      | 'COMBUSTION_COMMERCIAL_STANDARD_FUELS'
      | 'COMBUSTION_OTHER_GASEOUS_LIQUID_FUELS'
      | 'COMBUSTION_SOLID_FUELS'
      | 'COMBUSTION_FLARES'
      | 'OTHER'
      | 'COMBUSTION_GAS_PROCESSING_TERMINALS'
      | 'REFINERIES_MASS_BALANCE'
      | 'COKE_MASS_BALANCE'
      | 'METAL_ORE_MASS_BALANCE'
      | 'IRON_STEEL_MASS_BALANCE'
      | 'CARBON_BLACK_MASS_BALANCE_METHODOLOGY'
      | 'HYDROGEN_AND_SYNTHESIS_GAS_MASS_BALANCE_METHODOLOGY'
      | 'BULK_ORGANIC_CHEMICALS_MASS_BALANCE_METHODOLOGY'
      | 'NON_FERROUS_SEC_ALUMINIUM_MASS_BALANCE_METHODOLOGY'
      | 'SODA_ASH_SODIUM_BICARBONATE_MASS_BALANCE_METHODOLOGY'
      | 'PRIMARY_ALUMINIUM_MASS_BALANCE_METHODOLOGY'
      | 'REFINERIES_HYDROGEN_PRODUCTION'
      | 'CEMENT_CLINKER_CKD'
      | 'CERAMICS_SCRUBBING'
      | 'REFINERIES_CATALYTIC_CRACKER_REGENERATION'
      | 'COKE_FUEL_AS_PROCESS_INPUT'
      | 'IRON_STEEL_FUEL_AS_PROCESS_INPUT'
      | 'AMMONIA_FUEL_AS_PROCESS_INPUT'
      | 'HYDROGEN_AND_SYNTHESIS_GAS_FUEL_AS_PROCESS_INPUT'
      | 'COKE_CARBONATE_INPUT_METHOD_A'
      | 'COKE_OXIDE_OUTPUT_METHOD_B'
      | 'METAL_ORE_CARBONATE_INPUT'
      | 'IRON_STEEL_CARBONATE_INPUT'
      | 'CEMENT_CLINKER_KILN_INPUT_BASED_METHOD_A'
      | 'CEMENT_CLINKER_CLINKER_OUTPUT_METHOD_B'
      | 'CEMENT_CLINKER_NON_CARBONATE_CARBON'
      | 'LIME_DOLOMITE_MAGNESITE_CARBONATES_METHOD_A'
      | 'LIME_DOLOMITE_MAGNESITE_KILN_DUST_METHOD_B'
      | 'LIME_DOLOMITE_MAGNESITE_ALKALI_EARTH_OXIDE_METHOD_B'
      | 'GLASS_AND_MINERAL_WOOL_CARBONATES_INPUT'
      | 'CERAMICS_CARBON_INPUTS_METHOD_A'
      | 'CERAMICS_ALKALI_OXIDE_METHOD_B'
      | 'PULP_PAPER_MAKE_UP_CHEMICALS'
      | 'NON_FERROUS_SEC_ALUMINIUM_PROCESS_EMISSIONS'
      | 'COMBUSTION_SCRUBBING_CARBONATE'
      | 'COMBUSTION_SCRUBBING_UREA'
      | 'COMBUSTION_SCRUBBING_GYPSUM'
      | 'PRIMARY_ALUMINIUM_PFC',
  ): Observable<SourceStreamCalculationParametersInfo>;
  public getSourceStreamEmissionsCalculationInfoByType(
    sourceStreamType:
      | 'COMBUSTION_COMMERCIAL_STANDARD_FUELS'
      | 'COMBUSTION_OTHER_GASEOUS_LIQUID_FUELS'
      | 'COMBUSTION_SOLID_FUELS'
      | 'COMBUSTION_FLARES'
      | 'OTHER'
      | 'COMBUSTION_GAS_PROCESSING_TERMINALS'
      | 'REFINERIES_MASS_BALANCE'
      | 'COKE_MASS_BALANCE'
      | 'METAL_ORE_MASS_BALANCE'
      | 'IRON_STEEL_MASS_BALANCE'
      | 'CARBON_BLACK_MASS_BALANCE_METHODOLOGY'
      | 'HYDROGEN_AND_SYNTHESIS_GAS_MASS_BALANCE_METHODOLOGY'
      | 'BULK_ORGANIC_CHEMICALS_MASS_BALANCE_METHODOLOGY'
      | 'NON_FERROUS_SEC_ALUMINIUM_MASS_BALANCE_METHODOLOGY'
      | 'SODA_ASH_SODIUM_BICARBONATE_MASS_BALANCE_METHODOLOGY'
      | 'PRIMARY_ALUMINIUM_MASS_BALANCE_METHODOLOGY'
      | 'REFINERIES_HYDROGEN_PRODUCTION'
      | 'CEMENT_CLINKER_CKD'
      | 'CERAMICS_SCRUBBING'
      | 'REFINERIES_CATALYTIC_CRACKER_REGENERATION'
      | 'COKE_FUEL_AS_PROCESS_INPUT'
      | 'IRON_STEEL_FUEL_AS_PROCESS_INPUT'
      | 'AMMONIA_FUEL_AS_PROCESS_INPUT'
      | 'HYDROGEN_AND_SYNTHESIS_GAS_FUEL_AS_PROCESS_INPUT'
      | 'COKE_CARBONATE_INPUT_METHOD_A'
      | 'COKE_OXIDE_OUTPUT_METHOD_B'
      | 'METAL_ORE_CARBONATE_INPUT'
      | 'IRON_STEEL_CARBONATE_INPUT'
      | 'CEMENT_CLINKER_KILN_INPUT_BASED_METHOD_A'
      | 'CEMENT_CLINKER_CLINKER_OUTPUT_METHOD_B'
      | 'CEMENT_CLINKER_NON_CARBONATE_CARBON'
      | 'LIME_DOLOMITE_MAGNESITE_CARBONATES_METHOD_A'
      | 'LIME_DOLOMITE_MAGNESITE_KILN_DUST_METHOD_B'
      | 'LIME_DOLOMITE_MAGNESITE_ALKALI_EARTH_OXIDE_METHOD_B'
      | 'GLASS_AND_MINERAL_WOOL_CARBONATES_INPUT'
      | 'CERAMICS_CARBON_INPUTS_METHOD_A'
      | 'CERAMICS_ALKALI_OXIDE_METHOD_B'
      | 'PULP_PAPER_MAKE_UP_CHEMICALS'
      | 'NON_FERROUS_SEC_ALUMINIUM_PROCESS_EMISSIONS'
      | 'COMBUSTION_SCRUBBING_CARBONATE'
      | 'COMBUSTION_SCRUBBING_UREA'
      | 'COMBUSTION_SCRUBBING_GYPSUM'
      | 'PRIMARY_ALUMINIUM_PFC',
    observe: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<SourceStreamCalculationParametersInfo>>;
  public getSourceStreamEmissionsCalculationInfoByType(
    sourceStreamType:
      | 'COMBUSTION_COMMERCIAL_STANDARD_FUELS'
      | 'COMBUSTION_OTHER_GASEOUS_LIQUID_FUELS'
      | 'COMBUSTION_SOLID_FUELS'
      | 'COMBUSTION_FLARES'
      | 'OTHER'
      | 'COMBUSTION_GAS_PROCESSING_TERMINALS'
      | 'REFINERIES_MASS_BALANCE'
      | 'COKE_MASS_BALANCE'
      | 'METAL_ORE_MASS_BALANCE'
      | 'IRON_STEEL_MASS_BALANCE'
      | 'CARBON_BLACK_MASS_BALANCE_METHODOLOGY'
      | 'HYDROGEN_AND_SYNTHESIS_GAS_MASS_BALANCE_METHODOLOGY'
      | 'BULK_ORGANIC_CHEMICALS_MASS_BALANCE_METHODOLOGY'
      | 'NON_FERROUS_SEC_ALUMINIUM_MASS_BALANCE_METHODOLOGY'
      | 'SODA_ASH_SODIUM_BICARBONATE_MASS_BALANCE_METHODOLOGY'
      | 'PRIMARY_ALUMINIUM_MASS_BALANCE_METHODOLOGY'
      | 'REFINERIES_HYDROGEN_PRODUCTION'
      | 'CEMENT_CLINKER_CKD'
      | 'CERAMICS_SCRUBBING'
      | 'REFINERIES_CATALYTIC_CRACKER_REGENERATION'
      | 'COKE_FUEL_AS_PROCESS_INPUT'
      | 'IRON_STEEL_FUEL_AS_PROCESS_INPUT'
      | 'AMMONIA_FUEL_AS_PROCESS_INPUT'
      | 'HYDROGEN_AND_SYNTHESIS_GAS_FUEL_AS_PROCESS_INPUT'
      | 'COKE_CARBONATE_INPUT_METHOD_A'
      | 'COKE_OXIDE_OUTPUT_METHOD_B'
      | 'METAL_ORE_CARBONATE_INPUT'
      | 'IRON_STEEL_CARBONATE_INPUT'
      | 'CEMENT_CLINKER_KILN_INPUT_BASED_METHOD_A'
      | 'CEMENT_CLINKER_CLINKER_OUTPUT_METHOD_B'
      | 'CEMENT_CLINKER_NON_CARBONATE_CARBON'
      | 'LIME_DOLOMITE_MAGNESITE_CARBONATES_METHOD_A'
      | 'LIME_DOLOMITE_MAGNESITE_KILN_DUST_METHOD_B'
      | 'LIME_DOLOMITE_MAGNESITE_ALKALI_EARTH_OXIDE_METHOD_B'
      | 'GLASS_AND_MINERAL_WOOL_CARBONATES_INPUT'
      | 'CERAMICS_CARBON_INPUTS_METHOD_A'
      | 'CERAMICS_ALKALI_OXIDE_METHOD_B'
      | 'PULP_PAPER_MAKE_UP_CHEMICALS'
      | 'NON_FERROUS_SEC_ALUMINIUM_PROCESS_EMISSIONS'
      | 'COMBUSTION_SCRUBBING_CARBONATE'
      | 'COMBUSTION_SCRUBBING_UREA'
      | 'COMBUSTION_SCRUBBING_GYPSUM'
      | 'PRIMARY_ALUMINIUM_PFC',
    observe: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<SourceStreamCalculationParametersInfo>>;
  public getSourceStreamEmissionsCalculationInfoByType(
    sourceStreamType:
      | 'COMBUSTION_COMMERCIAL_STANDARD_FUELS'
      | 'COMBUSTION_OTHER_GASEOUS_LIQUID_FUELS'
      | 'COMBUSTION_SOLID_FUELS'
      | 'COMBUSTION_FLARES'
      | 'OTHER'
      | 'COMBUSTION_GAS_PROCESSING_TERMINALS'
      | 'REFINERIES_MASS_BALANCE'
      | 'COKE_MASS_BALANCE'
      | 'METAL_ORE_MASS_BALANCE'
      | 'IRON_STEEL_MASS_BALANCE'
      | 'CARBON_BLACK_MASS_BALANCE_METHODOLOGY'
      | 'HYDROGEN_AND_SYNTHESIS_GAS_MASS_BALANCE_METHODOLOGY'
      | 'BULK_ORGANIC_CHEMICALS_MASS_BALANCE_METHODOLOGY'
      | 'NON_FERROUS_SEC_ALUMINIUM_MASS_BALANCE_METHODOLOGY'
      | 'SODA_ASH_SODIUM_BICARBONATE_MASS_BALANCE_METHODOLOGY'
      | 'PRIMARY_ALUMINIUM_MASS_BALANCE_METHODOLOGY'
      | 'REFINERIES_HYDROGEN_PRODUCTION'
      | 'CEMENT_CLINKER_CKD'
      | 'CERAMICS_SCRUBBING'
      | 'REFINERIES_CATALYTIC_CRACKER_REGENERATION'
      | 'COKE_FUEL_AS_PROCESS_INPUT'
      | 'IRON_STEEL_FUEL_AS_PROCESS_INPUT'
      | 'AMMONIA_FUEL_AS_PROCESS_INPUT'
      | 'HYDROGEN_AND_SYNTHESIS_GAS_FUEL_AS_PROCESS_INPUT'
      | 'COKE_CARBONATE_INPUT_METHOD_A'
      | 'COKE_OXIDE_OUTPUT_METHOD_B'
      | 'METAL_ORE_CARBONATE_INPUT'
      | 'IRON_STEEL_CARBONATE_INPUT'
      | 'CEMENT_CLINKER_KILN_INPUT_BASED_METHOD_A'
      | 'CEMENT_CLINKER_CLINKER_OUTPUT_METHOD_B'
      | 'CEMENT_CLINKER_NON_CARBONATE_CARBON'
      | 'LIME_DOLOMITE_MAGNESITE_CARBONATES_METHOD_A'
      | 'LIME_DOLOMITE_MAGNESITE_KILN_DUST_METHOD_B'
      | 'LIME_DOLOMITE_MAGNESITE_ALKALI_EARTH_OXIDE_METHOD_B'
      | 'GLASS_AND_MINERAL_WOOL_CARBONATES_INPUT'
      | 'CERAMICS_CARBON_INPUTS_METHOD_A'
      | 'CERAMICS_ALKALI_OXIDE_METHOD_B'
      | 'PULP_PAPER_MAKE_UP_CHEMICALS'
      | 'NON_FERROUS_SEC_ALUMINIUM_PROCESS_EMISSIONS'
      | 'COMBUSTION_SCRUBBING_CARBONATE'
      | 'COMBUSTION_SCRUBBING_UREA'
      | 'COMBUSTION_SCRUBBING_GYPSUM'
      | 'PRIMARY_ALUMINIUM_PFC',
    observe: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<SourceStreamCalculationParametersInfo>;
  public getSourceStreamEmissionsCalculationInfoByType(
    sourceStreamType:
      | 'COMBUSTION_COMMERCIAL_STANDARD_FUELS'
      | 'COMBUSTION_OTHER_GASEOUS_LIQUID_FUELS'
      | 'COMBUSTION_SOLID_FUELS'
      | 'COMBUSTION_FLARES'
      | 'OTHER'
      | 'COMBUSTION_GAS_PROCESSING_TERMINALS'
      | 'REFINERIES_MASS_BALANCE'
      | 'COKE_MASS_BALANCE'
      | 'METAL_ORE_MASS_BALANCE'
      | 'IRON_STEEL_MASS_BALANCE'
      | 'CARBON_BLACK_MASS_BALANCE_METHODOLOGY'
      | 'HYDROGEN_AND_SYNTHESIS_GAS_MASS_BALANCE_METHODOLOGY'
      | 'BULK_ORGANIC_CHEMICALS_MASS_BALANCE_METHODOLOGY'
      | 'NON_FERROUS_SEC_ALUMINIUM_MASS_BALANCE_METHODOLOGY'
      | 'SODA_ASH_SODIUM_BICARBONATE_MASS_BALANCE_METHODOLOGY'
      | 'PRIMARY_ALUMINIUM_MASS_BALANCE_METHODOLOGY'
      | 'REFINERIES_HYDROGEN_PRODUCTION'
      | 'CEMENT_CLINKER_CKD'
      | 'CERAMICS_SCRUBBING'
      | 'REFINERIES_CATALYTIC_CRACKER_REGENERATION'
      | 'COKE_FUEL_AS_PROCESS_INPUT'
      | 'IRON_STEEL_FUEL_AS_PROCESS_INPUT'
      | 'AMMONIA_FUEL_AS_PROCESS_INPUT'
      | 'HYDROGEN_AND_SYNTHESIS_GAS_FUEL_AS_PROCESS_INPUT'
      | 'COKE_CARBONATE_INPUT_METHOD_A'
      | 'COKE_OXIDE_OUTPUT_METHOD_B'
      | 'METAL_ORE_CARBONATE_INPUT'
      | 'IRON_STEEL_CARBONATE_INPUT'
      | 'CEMENT_CLINKER_KILN_INPUT_BASED_METHOD_A'
      | 'CEMENT_CLINKER_CLINKER_OUTPUT_METHOD_B'
      | 'CEMENT_CLINKER_NON_CARBONATE_CARBON'
      | 'LIME_DOLOMITE_MAGNESITE_CARBONATES_METHOD_A'
      | 'LIME_DOLOMITE_MAGNESITE_KILN_DUST_METHOD_B'
      | 'LIME_DOLOMITE_MAGNESITE_ALKALI_EARTH_OXIDE_METHOD_B'
      | 'GLASS_AND_MINERAL_WOOL_CARBONATES_INPUT'
      | 'CERAMICS_CARBON_INPUTS_METHOD_A'
      | 'CERAMICS_ALKALI_OXIDE_METHOD_B'
      | 'PULP_PAPER_MAKE_UP_CHEMICALS'
      | 'NON_FERROUS_SEC_ALUMINIUM_PROCESS_EMISSIONS'
      | 'COMBUSTION_SCRUBBING_CARBONATE'
      | 'COMBUSTION_SCRUBBING_UREA'
      | 'COMBUSTION_SCRUBBING_GYPSUM'
      | 'PRIMARY_ALUMINIUM_PFC',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (sourceStreamType === null || sourceStreamType === undefined) {
      throw new Error(
        'Required parameter sourceStreamType was null or undefined when calling getSourceStreamEmissionsCalculationInfoByType.',
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (sourceStreamType !== undefined && sourceStreamType !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>sourceStreamType, 'sourceStreamType');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    const credential = this.configuration.lookupCredential('bearerAuth');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType_ = 'text';
    }

    return this.httpClient.get<SourceStreamCalculationParametersInfo>(
      `${this.configuration.basePath}/v1.0/reporting-data/calculation-parameters-info`,
      {
        params: queryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }
}
